export const company =
{
    name: 'RE/MAX Capital Property',
    heroTitle: 'Make Yourself at Home in Pattaya',
    mainServiceArea: 'Pattaya',
    phone1: '+66 33 135 376 (ENG/TH)',
    email: 'capitalproperty@remax.co.th',
    address1: '24/56 Moo 5 Nongprue, Pattaya',
    address2: 'Chonburi, Thailand 20150',
    phoneUrl: 'tel:006633135376',
    lineUrl: 'https://line.me/ti/p/~@cprop',
    whatsappUrl: '',
    youtubeUrl: 'https://www.youtube.com/@remaxcapitalpropertypattay87',
    facebookUrl: 'https://www.facebook.com/remaxcapitalpropertiespattaya',
    instagramUrl: '',
    tiktokUrl: '',
    linkedInUrl: '',
    OFFICE_LAT: 12.954054,
    OFFICE_LNG: 100.88996
}
